import Vue from 'vue'
import axios from 'axios'

const defaultState = () => {
  return {
    // 読み込み済みの共用部PDFファイル名
    commonAreaFileName: null,
    // 住戸の結果リスト
    householdResults: [],
    // エラー結果
    errorResults: [],
    // 住宅タイプのリスト
    householdsTypeList: [],
    // 住宅のリスト
    householdsList: [],
    // 住宅のアップロード状態
    isHouseholdInfoImported: false,
    // Job ID
    householdJobId: [],
    commonAreaJobId: null,
  }
}

export default {
  namespaced: true,

  state: defaultState(),

  getters: {
    //-------------------------------------------------------
    //            住戸部PDF
    //-------------------------------------------------------

    // 住戸部PDFアップロード処理のJobId
    getHouseholdJobId(state) {
        return state.householdJobId
    },
    // 読込したファイル名
    getFileNames(state) {
        return state.householdResults.map(function (el) { return el.originalName })
    },
    // 住戸リスト
    getHouseholdsList(state) {
        return state.householdsList
    },
    // 住戸タイプの名称一覧
    getHouseholdsTypeList(state) {
        return state.householdResults.map(function (el) { return el.householdType })
    },
    // 住戸のアップロード状態
    isHouseholdInfoImported(state) {
        return state.isHouseholdInfoImported
    },
    // 増改築PDFのリスト
    renovationFileList(state) {
        return state.errorResults
        .filter((el) => el.pdfResultType == 'renovation')
        .map(function (el) { return el.originalName })
    },

    //-------------------------------------------------------
    //            共用部PDF
    //-------------------------------------------------------

    // 共用部PDFアップロード処理のJobId
    getCommonAreaJobId(state) {
        return state.commonAreaJobId
    },
    // 読み込み済みの共用部PDFファイル名
    getCommonAreaFileName(state) {
        return state.commonAreaFileName
    },
    // 不正なファイルリスト
    incorrectFilesList(state) {
        return state.errorResults
        .filter((el) => el.pdfResultType == 'incorrect')
        .map(function (el) { return el.originalName })
    },
    // 許可されないversionのファイルリスト
    oldVersionFilesList(state) {
        return state.errorResults
        .filter((el) => el.pdfResultType == 'old_version')
        .map(function (el) { return el.originalName })
    },
    // エラーファイルの数
    errorFileCnt(_state, getters) {
        return getters.incorrectFilesList.length + getters.oldVersionFilesList.length
    }
  },

  mutations: {
    // stateのリセット
    reset(state, value){
        state = Object.assign(state, value)
    },
    // stateの更新
    update(state, values){
        Object.keys(values).forEach(key => {
            Vue.set(state, key, values[key])
        })
    },
    // stateのアペンド
    append(state, values){
        Object.keys(values).forEach(key => {
            Vue.set(state, key, state[key].concat(values[key]))
        })
    },
    // 住戸部分Pdfの削除
    deleteFile(state, value) {
        const delIndex = state.householdResults.findIndex((result) => result.originalName == value)

        if (delIndex > -1) {
            // 削除したファイルの住戸タイプを削除する
            const householdType = state.householdResults[delIndex].householdType
            state.householdsList.forEach(ele => {
                if (ele.householdType == householdType) ele.householdType = ""
            })
        }
        state.householdResults.splice(delIndex, 1)
    },
    // 住戸リストのリセット
    resetHouseholdsList(state) {
        state.isHouseholdInfoImported = false
        state.householdsList.forEach(value => {
            value.floor = ""
            value.householdNum = ""
            value.householdType = ""
        })
    }
  },

  actions: {
    // stateのリセットを呼び出す
    reset({commit}){
        commit('reset', defaultState())
    },
    // 住戸部分の一覧を消去
    clearHouseHoldsList({commit}){
        commit('update', {
            householdsList: []
        })
    },
    // 住戸部分の一覧を一括更新
    updateHouseHoldsList({commit}, householdsList){
        commit('update', {
            householdsList: householdsList
        })
    },
    // 住戸リストをインポート
    importHouseHoldsList({commit}, householdsList){
        commit('update', {
            error: [],
            householdsList: householdsList,
            isHouseholdInfoImported: true
        })
    },
    // 住戸情報のインポート状態の設定
    setHouseholdInfoImported({commit}, flag){
        commit('update', {isHouseholdInfoImported: flag})
    },
    // 住戸タイプの追加
    appendHouseholdTypes({commit}, householdTypes){
        commit('append', {householdsTypeList:householdTypes})
    },
    // 住戸部分Pdfの削除を呼び出す
    deleteFile({commit}, value) {
        commit('deleteFile', value)
    },
    // 住棟全体の住戸数によって住戸リストを作成する
    createHouseholdsListByHouseholdCnt({commit, state, rootGetters}) {
        const basicInfo = rootGetters['basicInfo/getBasicInfo']
        const diff = basicInfo.householdTotalNum - state.householdsList.length
        if (diff > 0) {
            for(let index = 0; index < diff; index ++){
                commit('append', {householdsList: {
                referenceId: state.householdsList.length + 1,
                floor: "",
                householdNum: "",
                householdType: ""
                }})
            }
        } else if(diff < 0) {
            const abs = Math.abs(diff)

            let householdsList = state.householdsList
            householdsList.splice(householdsList.length - abs, abs)
            commit('update', {householdsList:householdsList})
        }
    },
    // 住戸のJobIDを追加
    appendHouseholdJobId({commit}, value){
        commit('append', value)
    },
    // 共用部のJobIDを更新
    updateCommonAreaJobId({commit}, value) {
        commit('update', value)
    },
    // 読み込み済みの共用部PDFファイル名を更新
    updateCommonAreaFileName({commit}, value) {
        commit('update', value)
    },
    //住戸リストのリセット
    resetHouseholdsList({commit}, value) {
        commit('resetHouseholdsList', value)
    },
    // エラーファイルがある場合、メッセージを表示する
    showErrorFileMessage({dispatch, getters}) {
        if (getters.renovationFileList.length > 0) {
            dispatch('addError',
                "アップロードされた計算結果PDFが、増改築部分を対象とした評価結果である可能性があります。増改築部分を対象とした評価結果は集計できません。"
                + getters.renovationFileList.join(', ') ,
                { root : true})
        }
        if (getters.incorrectFilesList.length > 0) {
            dispatch('addError',
                "ファイルの内容の確認が出来ませんでした。以下に不適切なファイルが含まれているかご確認ください。"
                + getters.incorrectFilesList.join(', ') ,
                { root : true})
        }
        if (getters.oldVersionFilesList.length > 0) {
            dispatch('addError',
                "アップロードされた計算結果PDFが、現行版以外のエネルギー消費性能計算プログラムで作成されたものである可能性があります。"
                + getters.oldVersionFilesList.join(', '),
                { root : true})
        }
    },
    // エラー結果リストのリセット
    clearErrorResults({commit}) {
        commit('update', {'errorResults' : []})
    },

    //-------------------------------------------------------
    //            APIの処理
    //-------------------------------------------------------
    // Jobの実行状態の確認
    async checkJobStatus({dispatch}, data) {
        return axios.get(`/api/calculate/${data.type}/${data.jobId}/status`)
            .then(response => {
                return response.data
            }).catch(error => {
                const status = error.response.data
                if (status == 'timeout') {
                    dispatch('addError','タイムアウトしました。しばらく間を空けてから再度お試しください。', { root: true })
                } else {
                    let msg =''
                    if (data.type == 'commonarea') {
                        msg = 'データの取得ができませんでした。アップロードしたファイルを確認して下さい。' + data.filename
                    } else {
                        msg = 'エラーが発生しました。しばらくしてからもう一度お試し下さい。'
                    }
                    dispatch('addError', msg, { root: true })
                }
                return status
            })
    },
    // 住宅タイプのリスト取得
    async getHouseholdResults({dispatch, commit}, data) {
      return axios.get(`/api/calculate/${data.type}/${data.jobId}/result`)
        .then(response => {
            let updData = {errorResults : response.data.error_results}
            if (data.type == 'household') {
                updData.householdResults = response.data.pdfResults
            }
            commit('append', updData)
        }).catch(() => {
            dispatch('setError','エラーが発生しました。しばらくしてからもう一度お試し下さい。' ,{ root : true})
        })
    },
  },
}
